.css-1dr1loo {
  background: none !important;
  border: none !important;
}

.chakra-divider .css-1bykix3 {
  border-left: none !important;
  border-left-width: 0 !important;
}

.css-1bykix3 {
  border-left-width: 0 !important;
}
